<template>
  <v-overlay
    v-model="internalValue"
    class="v-overlay--material"
    v-bind="{
      ...$attrs
    }"
  >
    <base-material-snackbar
      v-model="internalValue"
      :color="type"
      :icon="$attrs.icon"
      :dismissible="dismissible"
      :type="type"
      multi-line
      top
      :timeout="-1"
    >
      <slot />
    </base-material-snackbar>
  </v-overlay>
</template>
<script>
  export default {
    name: 'BaseMaterialOverlayAlert',

    props: {
      dismissible: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: '',
      },
      value: Boolean,
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    watch: {
      internalValue (val, oldVal) {
        if (val === oldVal) return

        this.$emit('input', val)
      },
      value (val, oldVal) {
        if (val === oldVal) return

        this.internalValue = val
      },
    },
  }
</script>
