import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Lancements',
          path: '/launches',
          component: () => import('@/views/dashboard/scraper/Launches'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Nouveau lancement',
          path: '/launch/new',
          component: () => import('@/views/dashboard/scraper/NewLaunch'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Modifier le lancement',
          path: '/launch/edit/:id',
          props: true,
          component: () => import('@/views/dashboard/scraper/Launch'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Profils',
          path: '/profiles',
          component: () => import('@/views/dashboard/scraper/Profiles'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/pages/Index'),
      children: [
        // Login
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login'),
        },
      ],
    },
  ],
})

/**
 * Check if the user has authorization
 * If not redirect to the login
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user') == null) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
