<template>
  <v-snackbar
    v-model="internalValue"
    class="v-snackbar--material"
    v-bind="$attrs"
    :color="($attrs.color ? $attrs.color : type)"
    :timeout="((type === 'error' || type === 'warning' || type === 'info' ) ? -1 : 5000)"
  >
    <template>
      <v-icon
        v-if="type == 'error'"
        class="v-alert__icon"
      >
        mdi-alert
      </v-icon>
      <v-icon
        v-else-if="type == 'success'"
        class="v-alert__icon"
      >
        mdi-check-circle
      </v-icon>
      <v-icon
        v-else-if="type == 'warning'"
        class="v-alert__icon"
      >
        mdi-exclamation
      </v-icon>
      <v-icon
        v-else-if="type == 'info'"
        class="v-alert__icon"
      >
        mdi-information
      </v-icon>
      <v-icon
        v-else-if="$attrs.icon"
        class="v-alert__icon"
      >
        {{ $attrs.icon }}
      </v-icon>
    </template>
    <slot />
    <template
      v-if="dismissible"
      v-slot:action="{ attrs }"
    >
      <v-btn
        :aria-label="$vuetify.lang.t('$vuetify.close')"
        color
        icon
        small
        v-bind="attrs"
        @click="internalValue = false"
      >
        <v-icon>
          $vuetify.icons.cancel
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
  export default {
    name: 'BaseMaterialSnackbar',

    props: {
      dismissible: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: '',
      },
      value: Boolean,
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    watch: {
      internalValue (val, oldVal) {
        if (val === oldVal) return

        this.$emit('input', val)
      },
      value (val, oldVal) {
        if (val === oldVal) return

        this.internalValue = val
      },
    },
  }
</script>
