import axios from 'axios'

const $http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

const API_URL = '/user/'

class AuthService {
  /**
   * Login the user and save the authentication token
   *
   * @param {Object} user
   *                 user.email
   *                 user.password
   * @returns {Object} user logged in
   */
  login (user) {
    // console.log(user)

    return $http
      .post(API_URL + 'login', user)
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        } else {
          throw new Error('Invalid response')
        }

        return response.data
      })
  }

  /**
   * Logout user by removing it from localStorage
   */
  logout () {
    localStorage.removeItem('user')
  }

  /**
   * Register a new user in the database
   *
   * @param {Object} user
   *                 user.email
   *                 user.password
   */
  register (user) {
    return $http.post(API_URL + 'register', {
      email: user.email,
      password: user.password,
    })
  }
}

export default new AuthService()
