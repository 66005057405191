import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#424242',
  secondary: '#ff5a5f',
  accent: '#ff5a5f',
  info: '#00CAE3',
}
const darktheme = {
  primary: '#424242',
  secondary: '#ff5a5f',
  accent: '#ff5a5f',
  info: '#00CAE3',
}

export default new Vuetify({
  theme: {
    themes: {
      dark: darktheme,
      light: theme,
    },
    options: {
      themeCache: {
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
      },
    },
  },
})
